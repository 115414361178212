import { Injectable } from "@angular/core";
import { includes, snakeCase } from "lodash";
import * as JSZip from "jszip";
import { FileWithPath } from "file-selector";
import { FormDataRenamingParametersEnum } from "../enums/form-data/form-data-renaming-parameters.enum";
import { formatDate } from "@angular/common";
import ProviderItemModel from "../models/providers/provider-item.model";

export type RenamingParameters = {
  [key in FormDataRenamingParametersEnum]?: string;
};
@Injectable({
  providedIn: "root",
})
export class FileHelper {
  static FILE_TYPES = {
    "audio": ["audio/*"],
    "image": ["image/*"],
    "video": ["video/*"],
    "pdf": ["pdf"],
    "csv": ["csv"],
    "text": ["txt"],
    "json": ["json"],
    "xml": ["xml"],
    "yaml": ["yml", "yaml"],
    "document": ["doc", "docx", "odt"],
    "spreadsheet": ["xls", "xlsx", "ods"],
    "presentation": ["ppt", "pptx", "odp"],
    "archive": ["zip", "rar", "targz", "bz", "bz2", "tar", "gz", "jar", "7z"],
  };

  static PREVIEWABLE_MIME_TYPES = [
    "image/apng",
    "application/wasm",
    "audio/mp3",
    "audio/wav",
    "audio/x-flac",
    "audio/x-m4a",
    "application/javascript",
    "text/javascript",
    "video/x-m4v",
    "video/ogg",
    "application/epub+zip",
    "application/pdf",
    "video/mpeg",
    "audio/mpeg",
    "font/otf",
    "font/ttf",
    "font/woff",
    "font/woff2",
    "application/atom+xml",
    "application/ecmascript",
    "application/geo+json",
    "application/json",
    "application/jsonml+json",
    "application/ld+json",
    "application/manifest+json",
    "application/ogg",
    "application/rss+xml",
    "application/x-web-app-manifest+json",
    "application/xml",
    "audio/mp4",
    "audio/ogg",
    "audio/webm",
    "audio/x-wav",
    "image/bmp",
    "image/gif",
    "image/jpeg",
    "image/png",
    "image/svg+xml",
    "image/vnd.microsoft.icon",
    "image/webp",
    "image/x-icon",
    "image/x-xbitmap",
    "text/cache-manifest",
    "text/coffeescript",
    "text/css",
    "text/jade",
    "text/jsx",
    "text/less",
    "text/markdown",
    "text/mathml",
    "text/n3",
    "text/plain",
    "text/prs.lines.tag",
    "text/richtext",
    "text/sgml",
    "text/shex",
    "text/slim",
    "text/stylus",
    "text/troff",
    "text/turtle",
    "text/uri-list",
    "text/vnd.curl",
    "text/vnd.curl.dcurl",
    "text/vnd.curl.mcurl",
    "text/vnd.curl.scurl",
    "text/vnd.dvb.subtitle",
    "text/vnd.fly",
    "text/vnd.fmi.flexstor",
    "text/vnd.graphviz",
    "text/vnd.in3d.3dml",
    "text/vnd.in3d.spot",
    "text/vnd.wap.wml",
    "text/vnd.wap.wmlscript",
    "text/vtt",
    "text/x-asm",
    "text/x-c",
    "text/x-component",
    "text/x-fortran",
    "text/x-handlebars-template",
    "text/x-java-source",
    "text/x-lua",
    "text/x-markdown",
    "text/x-nfo",
    "text/x-opml",
    "text/x-org",
    "text/x-pascal",
    "text/x-processing",
    "text/x-sass",
    "text/x-scss",
    "text/x-setext",
    "text/x-sfv",
    "text/x-suse-ymp",
    "text/x-uuencode",
    "text/xml",
    "text/yaml",
  ];

  static CONVERTIBLE_EXTENSIONS = [
    ".bib",
    ".doc",
    ".xml",
    ".docx",
    ".fodt",
    ".html",
    ".ltx",
    ".txt",
    ".odt",
    ".ott",
    ".pdb",
    ".pdf",
    ".psw",
    ".rtf",
    ".sdw",
    ".stw",
    ".sxw",
    ".uot",
    ".vor",
    ".wps",
    ".epub",
    ".png",
    ".bmp",
    ".emf",
    ".eps",
    ".fodg",
    ".gif",
    ".jpg",
    ".met",
    ".odd",
    ".otg",
    ".pbm",
    ".pct",
    ".pgm",
    ".ppm",
    ".ras",
    ".std",
    ".svg",
    ".svm",
    ".swf",
    ".sxd",
    ".sxw",
    ".tiff",
    ".xhtml",
    ".xpm",
    ".fodp",
    ".potm",
    ".pot",
    ".pptx",
    ".pps",
    ".ppt",
    ".pwp",
    ".sda",
    ".sdd",
    ".sti",
    ".sxi",
    ".uop",
    ".wmf",
    ".csv",
    ".dbf",
    ".dif",
    ".fods",
    ".ods",
    ".ots",
    ".pxl",
    ".sdc",
    ".slk",
    ".stc",
    ".sxc",
    ".uos",
    ".xls",
    ".xlt",
    ".xlsx",
    ".tif",
    ".jpeg",
    ".odp",
  ];

  constructor() {}

  static isPreviewable(mimeType: string) {
    return this.PREVIEWABLE_MIME_TYPES.includes(mimeType);
  }

  static isDotFile(filename: string) {
    return filename.indexOf(".") === 0;
  }

  static isValidMimeType(mimeType: string, asteriskMimeType: string): boolean {
    let group = mimeType.split("/")[0],
      asteriskGroup = asteriskMimeType.split("/")[0];
    return group === asteriskGroup;
  }

  static isAccepted(file: File, accepted: string[]) {
    let extension = FileHelper.getExtension(file.name),
      mimeType = file.type;

    return accepted.some(
      (typeOrExtension: string) =>
        typeOrExtension === mimeType ||
        FileHelper.isValidMimeType(mimeType, typeOrExtension) ||
        typeOrExtension === extension ||
        this.FILE_TYPES[typeOrExtension]?.includes(extension),
    );
  }

  static isProviderItemAccepted(file: ProviderItemModel, accepted: string[]) {
    let extension = FileHelper.getExtension(file.name),
      mimeType = file.mimeType;

    return accepted.some(
      (typeOrExtension: string) =>
        typeOrExtension === mimeType ||
        FileHelper.isValidMimeType(mimeType, typeOrExtension) ||
        typeOrExtension === extension ||
        this.FILE_TYPES[typeOrExtension]?.includes(extension),
    );
  }

  static getExtension(filename: string) {
    return filename.split(".").pop();
  }

  static getSizeFromKilobytes(kilobytes: number) {
    return kilobytes * 1024;
  }

  static getSizeFromMegabytes(megabytes: number) {
    return megabytes * (1024 * 1024);
  }

  static generateAvailableName = (fileName: string, takenNames: string[]) => {
    if (!includes(takenNames, fileName)) return fileName;

    const [name, ...fileNameChunks] = (fileName || "").split(".");
    var desiredName = name,
      nameIndex = 0,
      newFileName;

    do {
      nameIndex++;
      desiredName = `${name} (${nameIndex})`;
      newFileName = [desiredName, ...fileNameChunks].join(".");
    } while (includes(takenNames, newFileName));

    return newFileName;
  };
  /**
   * Create a filename from formItem infos.
   * @param formatString A Mustache-like string pattern
   * @param renamingParameters Object of values used to create the filename
   * @returns
   */
  static formatFilename = (formatString: string, renamingParameters: RenamingParameters) => {
    let renamedFilename: string = null;
    let rp = renamingParameters;
    if (formatString) {
      renamedFilename = formatString;
      let { upload_date, item_label, uploader_name_or_email, conversation_title } = renamingParameters;
      rp.upload_date = formatDate(upload_date ?? new Date(), "yyyMMdd", "fr-FR"); // Added fr-FR as it does not change the output
      rp.item_label = snakeCase(item_label).toUpperCase();
      rp.uploader_name_or_email = snakeCase(uploader_name_or_email).toUpperCase();
      rp.conversation_title = snakeCase(conversation_title).toUpperCase();
      // Find matches and replace with data
      const regexp = new RegExp(/{{[{]?(.*?)[}]?}}/, "g");
      const matches = formatString.matchAll(regexp);
      for (const [pattern, key] of matches) {
        if (rp?.[key]) {
          renamedFilename = renamedFilename.replaceAll(pattern, rp[key]);
        }
      }
    }
    return renamedFilename;
  };

  static async zipFiles(files: (FileWithPath | DataTransferItem)[]) {
    let zip = new JSZip();
    let folderName = null;

    files.forEach((file: FileWithPath | DataTransferItem) => {
      let fileObj: File | FileWithPath;

      if (file.hasOwnProperty("getAsFile")) {
        fileObj = (file as DataTransferItem).getAsFile();
      } else {
        fileObj = file as FileWithPath;
      }

      const path = fileObj.webkitRelativePath;
      let pathChunks: string[] = path.split("/");

      if (folderName === null) {
        folderName = pathChunks[0];
      }

      // Create zip entry
      zip.file(pathChunks.slice(1, pathChunks.length).join("/"), fileObj, { createFolders: true });
    });

    const blob = await zip.generateAsync({ type: "blob", mimeType: "application/zip" });
    return new File([blob], `${folderName}.zip`, { type: "application/zip" });
  }
}
